import { Box, Typography } from "@mui/material";
import ReactIcon from '../../assets/project assets/skill-icons_react-light.png';
import TailwindIcon from '../../assets/project assets/skill-icons_tailwindcss-light.png';
import NodeIcon from '../../assets/project assets/skill-icons_nodejs-light.png';
import sqlIcon from '../../assets/project assets/skill-icons_mysql-light.png';
import FigmaIcon from '../../assets/project assets/skill-icons_figma-light.png';
import VscodeIcon from '../../assets/project assets/logos_visual-studio-code.png';
import GitHubIcon from '../../assets/project assets/bi_github.png';
import MUIIcon from '../../assets/project assets/MUIlogo.png';

const icons = [
  { src: ReactIcon, alt: 'React Icon' },
  { src: NodeIcon, alt: 'Node JS Icon' },
  { src: TailwindIcon, alt: 'Tailwind CSS Icon' },
  { src: MUIIcon, alt: 'Material UI Icon' },
  { src: sqlIcon, alt: 'My SQL Icon' },
  { src: FigmaIcon, alt: 'Figma Icon' },
  { src: VscodeIcon, alt: 'VS Code Icon' },
  { src: GitHubIcon, alt: 'Git Hub Icon' },
];


const cosmosSiteContent = (
    <Typography className="flex flex-col gap-2 mb-6">
      <p>
        I developed a robust NIC Validation Application that serves as a comprehensive tool for validating and managing National Identity Card (NIC) data. 
        The application was built using a modern tech stack, including React for the frontend, Node.js for the backend, Tailwind CSS, 
        and Material-UI (MUI) components for the user interface. The design was meticulously crafted using Figma, ensuring a user-friendly and 
        intuitive experience. For the database, I implemented MySQL, and the entire development process was managed in Visual Studio Code with 
        version control handled via GitHub. The application architecture follows the microservices approach, ensuring scalability and 
        maintainability.
      </p>
      <Box className="flex flex-row gap-4 my-3">
        {icons.map((icon, index) => (
          <img
            key={index}
            src={icon.src}
            alt={icon.alt}
            className="w-8 h-8"
          />
        ))}
      </Box>
      <p>
        The application’s functionality is centered around a secure and streamlined user experience. Upon accessing the platform, 
        users are prompted to sign up and subsequently sign in to their accounts. For added security, a password recovery feature is included, 
        where users can reset their passwords through an OTP (One-Time Password) sent to their registered email addresses. This feature ensures
        that users can securely regain access to their accounts by verifying their identity through the OTP process.
      </p>
      <p>
        Once logged in, users are greeted with a dynamic dashboard that offers a snapshot of the system’s data. This dashboard is the control 
        center of the application, where users can navigate to various features. One of the key functionalities is the Import File page, where users 
        can upload up to four CSV files containing NIC numbers. The system then validates these NIC numbers, extracting critical details such as 
        Birthday, Age, and Gender. This data is not only displayed to the user but is also stored in the database for future reference.
      </p>
      <p>
        The dashboard also provides visual insights into the data through gender-based bar charts and file-based pie charts, allowing users to 
        quickly assess the composition of their NIC data. Additionally, all validated data is presented in a comprehensive table that supports 
        filtering by file name and gender, as well as searching by NIC number. This ensures that users can efficiently manage and analyze the NIC 
        data they have uploaded.
      </p>
      <p>
        Beyond data validation and visualization, the application also includes a Reports page, where users can generate detailed reports of the 
        data stored in the database. These reports can be exported in various formats, including PDF, CSV, and Excel, providing flexibility in how 
        users choose to utilize the data.
      </p>
      <p>
        This NIC Validation Application showcases my ability to integrate multiple technologies and development practices into a cohesive, 
        user-focused application. The use of modern development tools and practices, such as microservices architecture and responsive UI design, 
        highlights my commitment to building scalable, maintainable, and user-friendly applications. This project serves as a testament to my skills 
        in full-stack development, from conceptual design through to deployment and user interaction.
      </p>
      <Box className='flex items-center gap-2'>
        <img src={GitHubIcon} alt="Git Hub Icon" className="w-8 h-8" />
        <a target="_blank" rel="noreferrer" href="https://github.com/Pravil-Wijesinghe/NIC-Validation-Application.git">
          https://github.com/Pravil-Wijesinghe/NIC-Validation-Application.git
        </a>
      </Box>
    </Typography>
  );

export default cosmosSiteContent;