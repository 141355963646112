import { Box, Typography } from "@mui/material";
import FigmaIcon from '../../assets/project assets/skill-icons_figma-light.png';
import IllustratorIcon from '../../assets/project assets/skill-icons_illustrator.png';
import BehanceIcon from '../../assets/project assets/behance-1.svg';

const icons = [
  { src: FigmaIcon, alt: 'Figma Icon' },
  { src: IllustratorIcon, alt: 'Illustrator Icon' },
];

const elderCareContent = (
    <Typography className="flex flex-col gap-2 mb-6">
      <p>
        I designed the UI for the Elder Care Health Tracker, a dual-platform solution with mobile and watch applications aimed at enhancing 
        care for elderly individuals. This project was developed using <b>Figma</b> for UI design and Adobe <b>Illustrator</b> for the logo. 
        The system serves two main user roles: the elder, who uses the watch app, and the caregiver, who uses the mobile app.
      </p>
      <Box className="flex flex-row gap-4 my-3">
        {icons.map((icon, index) => (
          <img
            key={index}
            src={icon.src}
            alt={icon.alt}
            className="w-8 h-8"
          />
        ))}
      </Box>
      <p>
        The <b>mobile application</b> starts with a splash screen and offers options to get started, create an account, or log in. Caregivers can 
        manage the elder’s health through features like <b>Workout, Medicine, SOS, Test, Call Doctor, Reminders, BMI Calculator,</b> and <b>Profile</b>. 
        Key functionalities include tracking physical activity, managing medication schedules, setting up emergency contacts, scheduling health 
        tests, contacting doctors, and setting reminders. The app also provides an easy-to-navigate interface with options for password 
        recovery and third-party sign-ups.
      </p>
      <p>
        The <b>watch application</b> for elders is designed for ease of use, with a simplified interface displaying key health tracking features. 
        It provides real-time updates on workouts, medication schedules, SOS functionality for emergencies, test schedules, and doctor contact 
        options. The app focuses on delivering essential information in a clear, accessible manner, ensuring that elders can manage their daily 
        health routines effectively.
      </p>
      <p>
        This design showcases my ability to create intuitive, user-friendly interfaces that cater to specific needs, enhancing the caregiving 
        experience while prioritizing accessibility and usability.
      </p>
      <Box className='flex items-center gap-2'>
        <img src={BehanceIcon} alt="Behance Icon" className="w-8 h-8" />
        <a target="_blank" rel="noreferrer" href="https://www.behance.net/gallery/203405097/Elder-Care-Health-Tracker-UI-Design">
          https://www.behance.net/Elder-Care-Health-Tracker-UI-Design
        </a>
      </Box>
    </Typography>
  );

export default elderCareContent;