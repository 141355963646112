import React from 'react';
import GithubLogo from '../assets/logo/mdi_github.png';
import BehanceLogo from '../assets/logo/mage_behance.png';
import LinkedinLogo from '../assets/logo/mdi_linkedin.png';
import InstaLogo from '../assets/logo/ri_instagram-fill.png';

const socialLinks = [
  {
    href: 'https://www.github.com',
    logo: GithubLogo,
    alt: 'Github Logo',
    name: 'Github',
  },
  {
    href: 'https://www.behance.net',
    logo: BehanceLogo,
    alt: 'Behance Logo',
    name: 'Behance',
  },
  {
    href: 'https://www.linkedin.com',
    logo: LinkedinLogo,
    alt: 'Linkedin Logo',
    name: 'LinkedIn',
  },
  {
    href: 'https://www.instagram.com',
    logo: InstaLogo,
    alt: 'Instagram Logo',
    name: 'Instagram',
  },
];

const SocialLink = ({ href, logo, alt, name }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="w-fit h-fit flex flex-col items-center"
  >
    <img src={logo} alt={alt} className="w-20" />
    <p className="text-white text-lg">{name}</p>
  </a>
);

const SocialLinks = () => (
  <div className="w-full flex flex-row justify-center gap-24">
    {socialLinks.map((link, index) => (
      <SocialLink key={index} href={link.href} logo={link.logo} alt={link.alt} name={link.name} />
    ))}
  </div>
);

export default SocialLinks;
