import React, { useRef } from 'react';
import { Box } from '@mui/material';
import Arrow from '../assets/Right-Arrow.png';
import MailLogo from '../assets/logo/formkit_email.png';
import PhoneLogo from '../assets/logo/ic_baseline-phone.png';
import LocationLogo from '../assets/logo/mdi_address-marker.png';
import FBLogo from '../assets/logo/ic_baseline-facebook.png';

// Data for navigation links
const navigationLinks = [
  { href: 'home', label: 'home' },
  { href: 'about', label: 'about me' },
  { href: 'skills', label: 'skills' },
  { href: 'projects', label: 'projects' },
  { href: 'activities', label: 'activities' },
];

// Data for contact details
const contactDetails = [
  { icon: MailLogo, text: 'pravilwijesinghe@gmail.com' },
  { icon: PhoneLogo, text: '+94 76 319 3321' },
  { icon: LocationLogo, text: 'Galle, Sri Lanka' },
];

// Social media links
const socialLinks = [
  { href: 'https://www.facebook.com', icon: FBLogo, label: '@pravil.wijesinghe' },
];

const Footer = ({ sectionRefs }) => {
    const handleScroll = (id) => {
      const section = sectionRefs[id]?.current;
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    };
  
    return (
      <div className="w-full flex flex-row justify-center gap-24 px-40">
        
        {/* Navigation Links Section */}
        <Box className="w-1/2 flex flex-col gap-6">
          {navigationLinks.map((link, index) => (
            <a
              key={index}
              onClick={() => handleScroll(link.href)}
              className="flex flex-row items-center gap-5 cursor-pointer"
            >
              <img src={Arrow} alt={`${link.label} arrow`} className="w-[13.12px] h-[14px]" />
              <p className="text-lg">{link.label}</p>
            </a>
          ))}
        </Box>
  
        {/* Contact and Social Links Section */}
        <Box className="w-1/2 flex flex-col gap-6">
          {/* Contact Information */}
          {contactDetails.map((contact, index) => (
            <Box key={index} className="flex flex-row items-center gap-5">
              <img src={contact.icon} alt="" className="" />
              <p className="text-lg">{contact.text}</p>
            </Box>
          ))}
  
          {/* Social Media Links */}
          {socialLinks.map((link, index) => (
            <a key={index} href={link.href} target="_blank" rel="noopener noreferrer" className="flex flex-row items-center gap-5">
              <img src={link.icon} alt={`${link.label} icon`} className="" />
              <p className="text-lg">{link.label}</p>
            </a>
          ))}
        </Box>
      </div>
    );
  };
  
  export default Footer;
