import React, { useState } from 'react';
import { Box, Button, Typography, Collapse } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const ProjectAccordion = ({ title, content, id }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box id={id} className='w-full bg-secondary rounded-md pr-6'>
      <Button
        variant='text'
        onClick={handleToggle}
        className='flex flex-row items-center text-primary w-full justify-start'
        sx={{ textTransform: 'none' }}
      >
      <KeyboardArrowRightIcon
          fontSize='medium'
          className={isOpen ? 'rotate-90 transition-transform duration-300' : 'transition-transform duration-300'}
        />
        <Typography className='text-xl font-bold'>{title}</Typography>
      </Button>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box className='ml-8 mt-4 text-justify'>
          {content}
        </Box>
      </Collapse>
    </Box>
  );
};

export default ProjectAccordion;
