import React from 'react';

const TechnicalSkillsLogo = ({ logo, altText }) => {
  return (
    <div className=''>
      <img src={logo} alt={altText} className={`w-12 cursor-pointer`} />
    </div>
  );
};

export default TechnicalSkillsLogo;
