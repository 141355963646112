import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import CppIcon from '../../assets/project assets/cpp_logo.png'
import IllustratorIcon from '../../assets/project assets/skill-icons_illustrator.png';
import VscodeIcon from '../../assets/project assets/logos_visual-studio-code.png';
import GitHubIcon from '../../assets/project assets/bi_github.png';


// Custom styles to add bullets to list items
const bulletListStyle = {
  listStyleType: "disc", // Adds disc-style bullets
  paddingLeft: "1.5rem", // Adjusts padding to align bullets
  "& .MuiListItem-root": {
    display: "list-item", // Ensures each ListItem displays as a list item
  },
};

const icons = [
  { src: CppIcon, alt: 'C++ Icon' },
  { src: IllustratorIcon, alt: 'Adobe Illustrator Icon' },
  { src: VscodeIcon, alt: 'VS Code Icon' },
  { src: GitHubIcon, alt: 'Git Hub Icon' },
];


const snakeGameContent = (
  <Typography className="flex flex-col gap-2 mb-6">
    <p>
      I developed a classic Snake Game as part of a group project, focusing on gameplay mechanics, design, and functionality. The game was
      created using C++, incorporating libraries such as '&lt;iostream&gt;', '&lt;fstream&gt;', '&lt;windows.h&gt;', '&lt;conio.h&gt;', '&lt;cstdlib&gt;',
      '&lt;ctime&gt;', and '&lt;vector&gt;'. The objective of the game is simple: the player controls a snake that grows longer each time it
      consumes randomly appearing eggs, earning points with each successful consumption. However, the game ends if the snake collides with
      its own tail or the screen’s boundaries.
    </p>
    <p>
      The game design, including its logo, was crafted using Adobe Illustrator, giving the game a visually appealing look that enhances
      the classic feel of the console-based gameplay. Players start with a clear set of instructions and are offered the option to begin at
      various speeds—normal, high, or slow—based on their preferred challenge level.
    </p>
    <List sx={bulletListStyle}>
      <ListItem>
        <ListItemText
          primary={
            <span>
              <b>User Controls:</b> The game is controlled via keyboard keys, with players moving the snake in four directions using arrow
              keys or specific letters ('a', 'w', 's', 'd'), making the game easy to play and navigate.
            </span>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={
            <span>
              <b>Dynamic Gameplay:</b> The snake increases in size with each egg consumed, and the score increments accordingly. Players can
              manually adjust the speed, adding an extra layer of challenge.
            </span>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={
            <span>
              <b>Challenges and Future Enhancements:</b> During development, managing interconnected functions and ensuring seamless gameplay
              was complex, but these challenges were overcome through careful coding and testing. Future plans include introducing a
              user-friendly interface, enhancing the game with sound effects, graphic upgrades, and possibly developing a multiplayer feature
              to expand the game's appeal.
            </span>
          }
        />
      </ListItem>
    </List>
    <Box className="flex flex-row gap-4 my-3">
        {icons.map((icon, index) => (
          <img
            key={index}
            src={icon.src}
            alt={icon.alt}
            className="h-8"
          />
        ))}
      </Box>
    <p>
      This project showcases my programming skills in C++ and my ability to develop engaging gameplay with attention to detail in both design
      and functionality.
    </p>
    <Box className='flex items-center gap-2'>
        <img src={GitHubIcon} alt="Git Hub Icon" className="w-8 h-8" />
        <a target="_blank" rel="noreferrer" href="https://bit.ly/pravil-snake-game">https://bit.ly/pravil-snake-game</a>
      </Box>
  </Typography>
);

export default snakeGameContent;
