import SCSEImage from '../assets/activities assets/SCSE 2024.png';

const ActivitiesInfro = [
    {
        name: "Media Derector",
        image: SCSEImage,
        description: "I am the Media Director for the Industrial Management Science Students' Association (IMSSA) for 2024/2025. IMSSA is the student group for the Department of Industrial Management at the University of Kelaniya.",
        moreDetails: "I am the Media Director for the Industrial Management Science Students' Association (IMSSA) for 2024/2025. IMSSA is the student group for the Department of Industrial Management at the University of Kelaniya.",
    },
    {
        name: "Activity 1",
        image: "image1.jpg",
        description: "Description 1",
        moreDetails: "Button 1",
    },
    {
        name: "Activity 1",
        image: "image1.jpg",
        description: "Description 1",
        moreDetails: "Button 1",
    },
    {
        name: "Activity 1",
        image: "image1.jpg",
        description: "Description 1",
        moreDetails: "Button 1",
    },
    {
        name: "Activity 1",
        image: "image1.jpg",
        description: "Description 1",
        moreDetails: "Button 1",
    },
    {
        name: "Activity 1",
        image: "image1.jpg",
        description: "Description 1",
        moreDetails: "Button 1",
    },
    {
        name: "Activity 1",
        image: "image1.jpg",
        description: "Description 1",
        moreDetails: "Button 1",
    },
];

export default ActivitiesInfro;