import React from 'react';
import { Box } from '@mui/material';

const roles = [
  {
    title: 'Front-end Developer',
    description: `As a front-end developer, I have over two years of experience creating responsive and visually appealing web interfaces. I am proficient in HTML, CSS, JavaScript, Tailwind CSS, and the React framework. My work includes developing numerous front-end projects, where I've applied my skills to build dynamic and user-friendly websites and applications.`,
  },
  {
    title: 'UI/UX Engineer',
    description: `In the field of UI/UX design, I have one year of experience working with Figma and Adobe XD. My focus is on creating intuitive and engaging user interfaces. With expertise in UI engineering, I have designed a variety of user interfaces, ensuring a seamless user experience through careful planning and attention to detail.`,
  },
  {
    title: 'Graphic Designer',
    description: `With over five years of experience as a graphic designer, I am skilled in using Illustrator, Photoshop, InDesign, After Effects, and Premiere Pro. My work spans across different mediums, from print to digital, allowing me to craft compelling visuals and graphics. My experience includes designing logos, branding materials, marketing collateral, and more.`,
  },
  {
    title: 'Mobile Application Developer',
    description: `As a mobile application developer, I have one year of experience using React Native and Android Studio. I have worked on several mobile app projects, developing apps that are both functional and user-friendly. My work involves coding, testing, and refining apps to meet user needs and enhance mobile experiences.`,
  },
];

const RoleCard = ({ title, description }) => (
  <Box className='w-full flex flex-col md:flex-row gap-10'>
    <h1 className='w-full md:w-1/3 text-3xl font-bold'>{title}</h1>
    <p className='w-full md:w-2/3 text-lg font-medium text-justify'>
      {description}
    </p>
  </Box>
);

const RolesList = () => (
  <div className='w-full flex flex-col gap-5'>
    {roles.map((role, index) => (
      <RoleCard key={index} title={role.title} description={role.description} />
    ))}
  </div>
);

export default RolesList;
