import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ActivitiesInfo from './ActivitiesInfo';
import { Box, Button, Modal, Typography } from '@mui/material';
import "./customSlider.css";

function ActivitiesCarousel() {
  const [open, setOpen] = useState(false);
  const [currentDetails, setCurrentDetails] = useState('');
  const [currentActivity, setCurrentActivity] = useState('');
  const [currentImage, setCurrentImage] = useState('');

  const handleOpen = (details, name, image) => {
    setCurrentActivity(name);
    setCurrentDetails(details);
    setCurrentImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Slider {...settings}>
        {ActivitiesInfo.map((activity, index) => (
          <Box
            key={index}
            className="relative bg-secondary rounded-xl text-primary h-[20rem] cursor-default"
          >
            <img
              src={activity.image}
              alt={activity.name}
              className="object-cover h-full w-full rounded-lg"
            />
            {/* Overlay with shade */}
            <Box className="overlay absolute inset-0 bg-primary bg-opacity-40 transition-all duration-500 hover:bg-opacity-80 flex justify-center items-end hover:items-center rounded-lg">
              <Box className="text-center text-white px-4">
                <h3 className="text-2xl font-semibold mb-2">{activity.name}</h3>
                <p className="text-sm font-medium">{activity.description}</p>
                <Button
                  variant="plain"
                  color="primary"
                  className="my-2"
                  onClick={() => handleOpen(activity.moreDetails, activity.name, activity.image)}
                >
                  Read More
                </Button>
              </Box>
            </Box>
          </Box>
        ))}
      </Slider>

      {/* Modal for displaying more details */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#DCE1E5',
            padding: '24px',
            borderRadius: '20px',
            boxShadow: 24,
            maxWidth: '1100px',
            maxHeight: '600px',
            width: '100%',
            height: '100%',
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
          }}
        >
            <img 
                src={currentImage}
                alt={currentImage}
                className="object-cover h-24 w-full"
            />
            <Typography id="modal-title" variant="h6" className="text-primary mb-7">
                {currentActivity}
            </Typography>
            <Typography id="modal-description" variant="body1">
                {currentDetails}
            </Typography>
            <Button
                variant="contained"
                color="error"
                size='small'
                onClick={handleClose}
                className="mt-4"
            >
                Close
            </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default ActivitiesCarousel;
