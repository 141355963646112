import { Box, Typography } from "@mui/material";
import ReactIcon from '../../assets/project assets/skill-icons_react-light.png';
import TailwindIcon from '../../assets/project assets/skill-icons_tailwindcss-light.png';
import FigmaIcon from '../../assets/project assets/skill-icons_figma-light.png';
import BehanceIcon from '../../assets/project assets/behance-1.svg';
import WeatherApiIcon from '../../assets/project assets/weatherAPI11.png';

const icons = [
  { src: ReactIcon, alt: 'React Icon' },
  { src: TailwindIcon, alt: 'Tailwind CSS Icon' },
  { src: FigmaIcon, alt: 'Figma Icon' },
  { src: WeatherApiIcon, alt: 'WeatherAPI Icon' },
];

const weatherAppContent = (
    <Typography className="flex flex-col gap-2 mb-6">
      <p>
        I developed a sleek and intuitive Weather Forecast Mobile Application designed to provide users with up-to-date weather information 
        in a visually appealing format. The UI was meticulously crafted using Figma, ensuring a clean and user-friendly design. The application 
        was built using React Native for cross-platform compatibility, with Tailwind CSS for responsive styling, and integrates data from a 
        Weather API to deliver accurate and real-time weather updates.
      </p>
      <Box className="flex flex-row gap-4 my-3">
        {icons.map((icon, index) => (
          <img
            key={index}
            src={icon.src}
            alt={icon.alt}
            className="w-8 h-8"
          />
        ))}
      </Box>
      <p>
        The user experience begins with a dynamic splash screen that sets the tone of the application with engaging visuals and branding. 
        After the splash screen, users are directed to the weather forecast screen, which serves as the main interface. This screen features 
        a search bar that allows users to easily look up weather conditions in any city of their choice.
      </p>
      <p>
        Once a city is selected, the app displays comprehensive weather details, including the current temperature, wind speed, sunrise time, 
        and humidity levels. Additionally, the app provides a seven-day weather forecast, allowing users to plan ahead with confidence. 
        Each day's forecast is visually presented, offering quick insights into expected weather conditions such as temperature ranges and 
        weather patterns.
      </p>
      <p>
        This project highlights my expertise in UI/UX design and mobile application development, combining modern design principles with 
        practical functionality. The application’s smooth navigation and real-time data integration make it a valuable tool for users seeking 
        reliable weather information on the go.
      </p>
      <Box className='flex items-center gap-2'>
        <img src={BehanceIcon} alt="Behance Icon" className="w-8 h-8" />
        <a target="_blank" rel="noreferrer" href="https://www.behance.net/gallery/201605189/Weather-Forecast-Mobile-Application">
        https://www.behance.net/Weather-Forecast-Mobile-Application
        </a>
      </Box>
    </Typography>
  );

export default weatherAppContent;