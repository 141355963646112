import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';

function NavBar() {
  const navRef = useRef();
  const [isNavVisible, setIsNavVisible] = useState(false);
  const showNavbar = () => {
    navRef.current.classList.toggle('transform-none');
    setIsNavVisible(!isNavVisible);
  };

  return (
    <div className='fixed z-50 w-full font-montserrat text-white bg-primary bg-opacity-95'>
      <header className='top-0 left-0 w-full lg:px-24 px-10 py-4 flex items-center justify-between'>
        <a href='/' className='text-2xl font-black'>
        PW
        </a>
        <nav
          ref={navRef}
          className='lg:relative fixed top-0 left-0 lg:text-lg text-xl font-medium flex lg:flex-row flex-col lg:gap-12 lg:transform-none lg:bg-opacity-0 bg-primary gap-10 w-full h-full lg:justify-end justify-center items-center transition duration-1000 transform -translate-y-full-vh'
        >
          <a href='about-me' className="relative before:content-[''] before:absolute before:top-full before:left-0 before:w-0 before:h-[2px] before:bg-white before:transition-all before:duration-300 hover:before:w-full">
            about me
          </a>
          <a href='/skills' className="relative before:content-[''] before:absolute before:top-full before:left-0 before:w-0 before:h-[2px] before:bg-white before:transition-all before:duration-300 hover:before:w-full">
            skills
          </a>
          <a href='/projects' className="relative before:content-[''] before:absolute before:top-full before:left-0 before:w-0 before:h-[2px] before:bg-white before:transition-all before:duration-300 hover:before:w-full">
            projects
          </a>
          <a href='/activities' className="relative before:content-[''] before:absolute before:top-full before:left-0 before:w-0 before:h-[2px] before:bg-white before:transition-all before:duration-300 hover:before:w-full">
            activities
          </a>
          <a href='/contact me' className="relative before:content-[''] before:absolute before:top-full before:left-0 before:w-0 before:h-[2px] before:bg-white before:transition-all before:duration-300 hover:before:w-full">
            contact me
          </a>
          {isNavVisible && (
            <Button
              onClick={showNavbar}
              className='absolute text-2xl top-8 right-8 text-white lg:hidden'
              size='medium'
              variant='text'
            >
              <CloseIcon className='text-3xl' />
            </Button>
          )}
        </nav>
        {!isNavVisible && (
          <Button
            onClick={showNavbar}
            className='text-white text-2xl lg:hidden'
            size='medium'
            variant='text'
          >
            <DensityMediumIcon className='text-3xl' />
          </Button>
        )}
      </header>
    </div>
  );
}

export default NavBar;
