import React, {useRef} from 'react';
import bgImg1 from '../assets/img1.jpg';
import NavBar from '../components/NavBar';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import profilePic from '../assets/Profile Pic.jpg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button } from '@mui/material';
import EducationTimelineItem from '../components/EducationTimelineItem';
import ExperienceTimelineItem from '../components/ExperienceTimelineItem';
import MobiOSLogo from '../assets/mobiOS Logo.jpg';
import TeacherLogo from '../assets/teacher.png';
import SkillButtonGroup from '../components/SkillButtonGroup';
import ProjectAccordion from '../components/ProjectAccordion';
import nicValidationAppContent from '../components/projectDetails/nicValidationAppContent';
import medicalCenterContent from '../components/projectDetails/medicalCenterContent';
import elderCareContent from '../components/projectDetails/elderCareContent';
import weatherAppContent from '../components/projectDetails/weatherAppContent';
import snakeGameContent from '../components/projectDetails/snakeGameContent';
import travelGuideAppContent from '../components/projectDetails/travelGuideAppContent';
import TechnicalSkillsSection from '../components/TechnicalSkillsSection';
import ActivitiesCarousel from '../components/ActivitiesCarousel';
import RolesList from '../components/RolesList';
import SocialLinks from '../components/SocialLinks';
import Footer from '../components/Footer';

export default function Index() {

  const skills = ['team working', 'adaptability', 'problem-solving', 'self learning', 'creativity', 'time management'];

  const interests = ['graphic designing', 'watching movies', 'reading', 'design thinking', 'astronomy', 'traveling'];

  // Create a ref for the "about-me" section
  const aboutMeRef = useRef(null);
  const homeRef = useRef(null);
  const skillsRef = useRef(null);
  const projectsRef = useRef(null);
  const activitiesRef = useRef(null);

  const sectionRefs = {
    home: homeRef,
    about: aboutMeRef,
    skills: skillsRef,
    projects: projectsRef,
    activities: activitiesRef,
  };

  const handleDownloadCV = () => {
    // Path to PDF file
    const pdfUrl = '/Pravil Wijesinghe.pdf';

    // Create a new link element
    const link = document.createElement('a');
    
    // Set the href and target attributes for the link
    link.href = pdfUrl;
    link.target = '_blank';  // Open in a new tab
    
    // Trigger a download
    link.download = 'Pravil Wijesinghe CV.pdf';  // The file will be downloaded as 'Pravil Wijesinghe CV.pdf'
    
    // Programmatically click the link
    link.click();
  };

  // Function to handle scroll
  const handleScrollToAboutMe = () => {
    // Scroll to the "about-me" section
    aboutMeRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='font-sans'>
      <div className=''>
        <NavBar/>
        <img src={bgImg1} alt='bg_image' className='absolute w-full h-screen opacity-5 z-0'/>
        <div ref={homeRef} id='home' className='flex justify-center relative w-full h-screen bg-primary text-white'>
          <div className='absolute flex flex-col gap-2 items-center justify-center w-full h-screen z-10'>
              <h2 className='text-5xl font-semibold'>Hey there, I'm</h2>
              <h1 className='text-9xl font-extrabold'>Pravil Wijesinghe</h1>
              <div className='flex flex-row w-full items-center justify-center gap-14 mt-6'>
                {['front-end developer', 'ui/ux engineer', 'graphic designer', 'mobile application developer'].map((label) => (
                  <Button
                    key={label}
                    variant="outlined"
                    href="#"
                    className='w-fit h-fit border-white rounded-full px-3 transition-colors duration-500 hover:bg-white hover:text-primary text-white'
                    sx={{
                      textTransform: 'none',  // This disables the default uppercase transformation
                      borderWidth: 2,
                      '&:hover': {
                        borderWidth: 2,
                      },
                    }}
                  >
                    {label}
                  </Button>
                ))}
              </div>
          </div>
          <button onClick={handleScrollToAboutMe} className='absolute z-10 flex flex-col items-center bottom-3 w-fit text-white bg-opacity-0'>
              <h4 className='font-bold animate-shake'>click</h4>
              <h4 className='animate-shake'>to scroll down</h4>
              <KeyboardDoubleArrowDownIcon className='animate-shake'/>
          </button>
        </div>
        <div id='about-me' ref={aboutMeRef} className='w-full text-primary flex flex-col items-center gap-7 px-36 mt-16'>
          <div className='flex items-center justify-center'>
            <h4 className='text-4xl font-bold'>about me</h4>
            <h1 className='absolute -z-10 text-7xl opacity-60 font-black text-secondary'>about me</h1>
          </div>
          <div className='w-full flex flex-row items-center gap-10'>
            <div className='w-fit'>
              <img src={profilePic} alt='Profile-Picture' className='w-80 rounded-full outline outline-primary outline-8'/>
            </div>
            <div className='w-fit flex flex-col gap-6'>
              <p className='text-justify text-lg'>
                <b>Hello!</b> I'm an IT student at the University of Kelaniya, Sri Lanka, pursuing a B.Sc. in Information Technology. I have a deep 
                passion for creating software and designing websites, and my goal is to use technology to make a positive difference in the world. 
                My skills include software development, where I enjoy writing code and building applications, and UI/UX design, 
                where I focus on making software and websites look good and easy to use. I also have experience in web development, 
                creating websites from scratch, and graphic design, designing visuals and graphics that enhance user experiences.
                <br/><br/>
                I'm always eager to learn new things and take on challenges. I believe that technology can solve real problems and 
                help people, and I strive to work on projects that do just that. In the future, I hope to be part of a team that 
                creates innovative tech solutions for both big and small issues.
              </p>
              <div className='flex gap-4'>
                <Button variant='outlined' onClick={handleDownloadCV}
                    sx={{
                      textTransform: 'none',
                      borderWidth: 2,
                      '&:hover': {
                        borderWidth: 2,
                      },
                    }}
                    className='border-primary putline-4 rounded-full px-3 py-1transition-colors duration-500 hover:bg-primary hover:text-white text-primary'>
                    download cv
                </Button>
                <Button variant='outlined' 
                  sx={{
                    textTransform: 'none',
                    borderWidth: 2,
                    '&:hover': {
                      borderWidth: 2,
                    },
                  }}
                  className='bg-primary border-primary rounded-full w-fit px-3 py-1 text-white hover:bg-white hover:text-primary flex items-center gap-2'>
                  contact me
                  <ArrowForwardIcon/>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div id='education&experience' className='w-full text-primary flex flex-row justify-center px-36 mt-16'>
          <div id='education' className='flex flex-col w-1/2 items-center gap-7'>
            <div className='flex items-center justify-center'>
              <h4 className='text-4xl font-bold'>education</h4>
              <h1 className='absolute -z-10 text-7xl opacity-60 font-black text-secondary'>education</h1>
            </div>
            <div className='flex flex-col w-full justify-center items-center gap-2'>
              <EducationTimelineItem
                year="2022 - present"
                title="B.Sc. (Hons) in Information Technology"
                details={
                  <>
                    Department of Industrial Management<br />
                    Faculty of Science<br />
                    University of Kelaniya<br />
                    Sri Lanka
                  </>
                }
              />
              <EducationTimelineItem
                year="2018 - 2020"
                title="G. C. E. Advance Level"
                details={
                  <>
                    Defence Services College - Colombo 02<br />
                    Information Technology - A<br />
                    Combined Maths - B<br />
                    Physics - C
                  </>
                }
              />
            </div>
          </div>
          <div id='experience' className='flex flex-col w-1/2 items-center gap-7'>
            <div className='flex items-center justify-center'>
                <h4 className='text-4xl font-bold'>experience</h4>
                <h1 className='absolute -z-10 text-7xl opacity-60 font-black text-secondary'>experience</h1>
            </div>
            <div className='flex flex-col w-fit justify-center items-center gap-7'>
              <ExperienceTimelineItem
                logo={MobiOSLogo}
                role="Software Engineer - Internship"
                company="MobiOS Private Limited"
                dates="2024 August - present"
              />
              <ExperienceTimelineItem
                logo={TeacherLogo}
                role="Private Class Tutor"
                company="IT class teacher (O/L and A/L ICT)"
                dates="2022 - present"
              />
            </div>
          </div>
        </div>
        <div ref={skillsRef} id='skills&interests' className='w-full text-primary flex flex-row justify-center px-36 mt-16'>
          <div id='technical-skills' className='flex flex-col w-1/2 items-center gap-7'>
            <div className='flex items-center justify-center'>
              <h4 className='text-4xl font-bold'>technical skills</h4>
              <h1 className='absolute -z-10 text-7xl opacity-60 font-black text-secondary'>technical skills</h1>
            </div>
            <div className='flex flex-col gap-8 items-start'>
                <TechnicalSkillsSection/>
            </div>
          </div>
          <div id='soft-skills&interests' className='flex flex-col w-1/2 items-center gap-7'>
            <div id='soft-skills' className='flex flex-col gap-7'>
              <div className='flex items-center justify-center'>
                  <h4 className='text-4xl font-bold'>soft skills</h4>
                  <h1 className='absolute -z-10 text-7xl opacity-60 font-black text-secondary'>soft skills</h1>
              </div>
              <div className='flex flex-row w-fit justify-center items-center gap-2'>
                <SkillButtonGroup skills={skills} />
              </div>
            </div>
            <div id='interests' className='flex flex-col gap-7'>
              <div className='flex items-center justify-center'>
                  <h4 className='text-4xl font-bold'>interests</h4>
                  <h1 className='absolute -z-10 text-7xl opacity-60 font-black text-secondary'>interests</h1>
              </div>
              <div className='flex flex-row w-fit justify-center items-center gap-7'>
                <SkillButtonGroup skills={interests} />
              </div>
            </div>
          </div>
        </div>
        <div ref={projectsRef} id='projects' className='w-full text-primary flex flex-col items-center gap-7 px-36 mt-16'>
          <div className='flex items-center justify-center'>
            <h4 className='text-4xl font-bold'>projects</h4>
            <h1 className='absolute -z-10 text-7xl opacity-60 font-black text-secondary'>projects</h1>
          </div>
          <div className='w-full flex flex-col items-start gap-4'>
            <ProjectAccordion
              title="nic validation application"
              content={nicValidationAppContent}
              id="nic-validation-application"
            />
            <ProjectAccordion
              title="medical center management system"
              content={medicalCenterContent}
              id="medical-center-management-system"
            />
            <ProjectAccordion
              title="elder care health tracker"
              content={elderCareContent}
              id="elder-care-health-tracker"
            />
            <ProjectAccordion
              title="weather mobile application"
              content={weatherAppContent}
              id="weather-mobile-application"
            />
            <ProjectAccordion
              title="snake game"
              content={snakeGameContent}
              id="snake-game"
            />
            <ProjectAccordion
              title="travel guide mobile application"
              content={travelGuideAppContent}
              id="travel-guide-mobile-application"
            />
          </div>
        </div>
        <div ref={activitiesRef} id='activities' className='w-full text-primary flex flex-col items-center gap-7 px-36 mt-16'>
          <div className='flex items-center justify-center'>
            <h4 className='text-4xl font-bold'>activities</h4>
            <h1 className='absolute -z-10 text-7xl opacity-60 font-black text-secondary'>activities</h1>
          </div>
          <div className='w-full flex flex-col gap-4'>
            <ActivitiesCarousel/>
          </div>
        </div>
        <div id='roles' className='w-full text-primary flex flex-col items-center gap-7 px-36 mt-16'>
          <div className='flex items-center justify-center'>
            <h4 className='text-4xl font-bold'>roles</h4>
            <h1 className='absolute -z-10 text-7xl opacity-60 font-black text-secondary'>roles</h1>
          </div>
          <div className='w-full flex flex-col gap-5'>
            <RolesList/>
          </div>
        </div>
        <div id='contact-info' className='w-full h-full text-white bg-primary flex flex-col items-center mt-16 pb-16'>
          <div id='connect-with-my-works' className='w-full flex flex-col items-center gap-7 px-36 mt-16'>
            <div className='flex items-center justify-center'>
              <h4 className='text-4xl font-bold'>connect with my works</h4>
              <h1 className='absolute -z-0 text-7xl opacity-10 font-black text-secondary'>connect with my works</h1>
            </div>
            <div className='w-full flex flex-row justify-center gap-24'>
              <SocialLinks/>
            </div>
          </div>
          <div id='connect-with-my-works' className='w-full flex flex-col items-center gap-7 px-36 mt-16'>
            <div className='flex items-center justify-center'>
              <h4 className='text-4xl font-bold'>contact me</h4>
              <h1 className='absolute -z-0 text-7xl opacity-10 font-black text-secondary'>contact me</h1>
            </div>
            <div className='w-full flex flex-row justify-center gap-24 px-40'>
              <Footer sectionRefs={sectionRefs}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
