import React from 'react';
import { Typography } from '@mui/material';

const ExperienceTimelineItem = ({ logo, role, company, dates }) => {
  return (
    <div className='flex flex-row w-full justify-start items-center gap-5'>
      <div className='w-14 rounded-full outline outline-2 outline-primary'>
        <img src={logo} alt={`${company} Logo`} className='w-full h-full object-cover rounded-full'/>
      </div>
      <div className='w-[3px] h-10 rounded-full bg-primary'></div>
      <Typography>
        <Typography className='font-semibold'>{role}</Typography>
        {company}<br/>
        {dates}
      </Typography>
    </div>
  );
};

export default ExperienceTimelineItem;
