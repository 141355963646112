import React from 'react';
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Index from './pages/Index';

function App() {
  return (
    <div className="App">
      <Router> {/* Changed BrowserRouter to HashRouter */}
        <Routes>
          <Route path='/' element={<Index />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;