import React from 'react';
import { Button } from '@mui/material';

const SkillButtonGroup = ({ skills }) => {
  // Split skills into chunks of 3
  const chunkedSkills = skills.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 3);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // Start a new chunk
    }

    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

  return (
    <div className='w-full flex flex-col gap-4'>
      {chunkedSkills.map((row, rowIndex) => (
        <div key={rowIndex} className='flex flex-row gap-4 justify-start'>
          {row.map((label, index) => (
            <Button
              key={index}
              variant="outlined"
              className='w-fit h-fit border-primary rounded-full px-3 transition-colors duration-500 hover:bg-primary hover:border-primary hover:text-white text-primary'
              sx={{
                textTransform: 'none',
                borderWidth: 2,
                '&:hover': {
                  borderWidth: 2,
                },
              }}
            >
              {label}
            </Button>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SkillButtonGroup;
