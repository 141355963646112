import { Box, Typography } from "@mui/material";
import AndroidStudioIcon from '../../assets/project assets/android-studio-icon.webp';
import FigmaIcon from '../../assets/project assets/skill-icons_figma-light.png';
import IllustratorIcon from '../../assets/project assets/skill-icons_illustrator.png';
import BehanceIcon from '../../assets/project assets/behance-1.svg';

const icons = [
  { src: AndroidStudioIcon, alt: 'Android Studio Icon' },
  { src: FigmaIcon, alt: 'Figma Icon' },
  { src: IllustratorIcon, alt: 'Adobe Illustrator Icon' },
];

const travelGuideAppContent = (
    <Typography className="flex flex-col gap-2 mb-6">
      <p>
        I designed and developed a mobile application called TravelEase, a user-friendly travel guide app that enhances the travel 
        experience by providing comprehensive information on various destinations. The UI was meticulously crafted using Figma, ensuring an 
        intuitive and visually engaging interface, while the logo was designed in Adobe Illustrator, capturing the essence of adventure and 
        exploration. The app was developed in Android Studio, focusing on delivering a seamless and interactive user experience.
      </p>
      <Box className="flex flex-row gap-4 my-3">
        {icons.map((icon, index) => (
          <img
            key={index}
            src={icon.src}
            alt={icon.alt}
            className="w-8 h-8"
          />
        ))}
      </Box>
      <p>
        Upon opening TravelEase, users can create an account or log in with their existing credentials. After logging in, they are directed 
        to the Home page, which showcases a curated list of popular travel destinations. Each destination is presented with a visually appealing 
        card, inviting users to explore further. When a user clicks on a destination, they are taken to a detailed page that provides extensive 
        information about the place, including its description, a gallery of photos, and user ratings. This page allows users to interact by 
        rating the destination themselves and adding comments, fostering a community of shared experiences and insights.
      </p>
      <p>
        The detailed page also features an Add to Favorites button, enabling users to save destinations they are interested in for easy access 
        later. These favorites can be viewed on the user’s Favorites page, which collects all the saved destinations, making it simple for 
        users to keep track of the places they wish to visit.
      </p>
      <p>
        TravelEase also values user feedback, offering a section where users can provide their opinions on the app and the destinations featured. 
        This feedback loop helps improve the app's functionality and ensures that the information provided remains current and user-focused.
      </p>
      <p>
        In addition to exploring destinations, users can manage their accounts through the Profile section. Here, they can view and update 
        their personal details, change their password, and edit their profile information. A simple Logout option ensures that users can 
        securely exit their accounts.
      </p>
      <p>
        TravelEase exemplifies my skills in UI/UX design and mobile development, combining modern design aesthetics with practical, 
        user-centered features. This project highlights my ability to create a cohesive and engaging digital experience that caters to travelers 
        looking for inspiration, information, and a way to share their journeys.
      </p>
      <Box className='flex items-center gap-2'>
        <img src={BehanceIcon} alt="Behance Icon" className="w-8 h-8" />
        <a target="_blank" rel="noreferrer" href="https://www.behance.net/gallery/201542945/Find-Travel-Destination-Mobile-Application-UI">
        https://www.behance.net/Find-Travel-Destination-Mobile-Application-UI
        </a>
      </Box>
    </Typography>
  );

export default travelGuideAppContent;