import React from 'react';
import { Typography } from '@mui/material';

const EducationTimelineItem = ({ year, title, details }) => {
  return (
    <div className='flex flex-row w-full justify-center items-start gap-5'>
      <Typography className='font-semibold'>{year}</Typography>
      <div>
        <Typography className='font-semibold'>{title}</Typography>
        <Typography>{details}</Typography>
      </div>
    </div>
  );
};

export default EducationTimelineItem;
