import { Box, Typography } from "@mui/material";
import ReactIcon from '../../assets/project assets/skill-icons_react-light.png';
import TailwindIcon from '../../assets/project assets/skill-icons_tailwindcss-light.png';
import NodeIcon from '../../assets/project assets/skill-icons_nodejs-light.png';
import sqlIcon from '../../assets/project assets/skill-icons_mysql-light.png';
import FigmaIcon from '../../assets/project assets/skill-icons_figma-light.png';
import IllustratorIcon from '../../assets/project assets/skill-icons_illustrator.png';
import VscodeIcon from '../../assets/project assets/logos_visual-studio-code.png';
import GitHubIcon from '../../assets/project assets/bi_github.png';

const icons = [
  { src: ReactIcon, alt: 'React Icon' },
  { src: NodeIcon, alt: 'Node JS Icon' },
  { src: TailwindIcon, alt: 'Tailwind CSS Icon' },
  { src: sqlIcon, alt: 'My SQL Icon' },
  { src: FigmaIcon, alt: 'Figma Icon' },
  { src: IllustratorIcon, alt: 'Illustrator Icon' },
  { src: VscodeIcon, alt: 'VS Code Icon' },
  { src: GitHubIcon, alt: 'Git Hub Icon' },
];

const medicalCenterContent = (
    <Typography className="flex flex-col gap-2 mb-6">
      <p>
        I recently developed a web application for a medical center management system operated by Dr. P. P. Wijesekara. 
        This system is built using modern technologies such as the React framework, Tailwind CSS, NodeJS, MySQL, 
        and Hero Icons. For UI design, I used Figma, and for creating the logo and other components, 
        I utilized Adobe Illustrator. Visual Studio Code was my go-to development environment, 
        and GitHub was used for version control.
      </p>
      <Box className="flex flex-row gap-4 my-3">
        {icons.map((icon, index) => (
          <img
            key={index}
            src={icon.src}
            alt={icon.alt}
            className="w-8 h-8"
          />
        ))}
      </Box>
      <p>
        The system supports four user roles: Patient, Doctor, Admin, and Staff. Each user must log in to access their 
        respective functionalities. Patients can register using their personal details and manage their family members' 
        appointments and medical history. Doctors have dashboards to view their schedules, patient lists, and update 
        appointments with diagnosis and treatment plans. Admins manage appointments, patients, doctors, and inventory, 
        while staff members handle patient details, appointments, and e-billing.
      </p>
      <p>
        Key features include real-time tracking of medicine stock levels, 24/7 system availability, 
        data encryption for security, and an intuitive user interface. The system aims to enhance the patient experience by 
        allowing online registration, appointment scheduling, and access to medical records. It also streamlines doctor 
        and staff operations by providing tools to manage their tasks efficiently. With the integration of MySQL for 
        reliable data management and the use of modern development tools, the system is both robust and scalable.
      </p>
      <p>
        Future enhancements could include developing a mobile application for better accessibility, 
        implementing advanced reporting and analytics, and integrating with other healthcare systems for seamless data 
        exchange. This project represents a significant step towards improving the management and operation of medical 
        centers through technology.
      </p>
      <Box className='flex items-center gap-2'>
        <img src={GitHubIcon} alt="Git Hub Icon" className="w-8 h-8" />
        <a target="_blank" rel="noreferrer" href="https://bit.ly/medical-center-management-system">https://bit.ly/medical-center-management-system</a>
      </Box>
    </Typography>
  );

export default medicalCenterContent;