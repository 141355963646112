import React from 'react';
import { Tooltip } from '@mui/material';
import htmlLogo from '../assets/technical skills/akar-icons_html-fill.png';
import TechnicalSkillsLogo from './TechnicalSkillsLogo';
import cssLogo from '../assets/technical skills/akar-icons_css-fill.png';
import reactLogo from '../assets/technical skills/mdi_react.png';
import phpLogo from '../assets/technical skills/akar-icons_php-fill.png';
import jsLogo from '../assets/technical skills/ri_javascript-fill.png';
import figmaLogo from '../assets/technical skills/solar_figma-bold-duotone.png';
import sqlLogo from '../assets/technical skills/simple-icons_mysql.png';
import pythonLogo from '../assets/technical skills/akar-icons_python-fill.png';
import javaLogo from '../assets/technical skills/ri_java-fill.png';
import cppLogo from '../assets/technical skills/c- (1) 1.png';
import aiLogo from '../assets/technical skills/mage_illustrator.png';
import psLogo from '../assets/technical skills/mage_photoshop.png';
import idLogo from '../assets/technical skills/simple-icons_adobeindesign.png';
import aeLogo from '../assets/technical skills/mage_after-effects.png';
import prLogo from '../assets/technical skills/basil_adobe-premiere-solid.png';
import xdLogo from '../assets/technical skills/devicon-plain_xd.png';

// Group logos into arrays
const skillSet1 = [
  { logo: htmlLogo, altText: "HTML" },
  { logo: cssLogo, altText: "CSS" },
  { logo: reactLogo, altText: "React JS" },
  { logo: phpLogo, altText: "PHP" },
  { logo: jsLogo, altText: "Node JS" },
];

const skillSet2 = [
  { logo: figmaLogo, altText: "Figma" },
  { logo: sqlLogo, altText: "MySQL" },
  { logo: pythonLogo, altText: "Python" },
  { logo: javaLogo, altText: "Java" },
  { logo: cppLogo, altText: "C++" },
];

const skillSet3 = [
  { logo: aiLogo, altText: "Adobe Illustrator" },
  { logo: psLogo, altText: "Adobe Photoshop" },
  { logo: idLogo, altText: "Adobe Indesign" },
  { logo: aeLogo, altText: "Adobe After Effect" },
  { logo: prLogo, altText: "Adobe Premiere pro" },
];

const skillSet4 = [
    { logo: xdLogo, altText: "Adobe XD" },
];

const TechnicalSkillsSection = () => (
  <div className='flex flex-col gap-8 items-start'>
    {[skillSet1, skillSet2, skillSet3, skillSet4].map((skillSet, setIndex) => (
      <div key={setIndex} className='flex flex-row gap-11'>
        {skillSet.map((skill, index) => (
          <Tooltip key={index} title={skill.altText} arrow>   {/* Add popup message */}
            <div>
              <TechnicalSkillsLogo logo={skill.logo} altText={skill.altText} />
            </div>
          </Tooltip>
        ))}
      </div>
    ))}
  </div>
);

export default TechnicalSkillsSection;
